<template>
   <div class="wearing">
     <div class="content">
        <div class="time">
           <p>旧镜验配时间</p>
           <div class="time-xz">
             <el-dropdown class="sx-op" trigger="click" placement="bottom" @command="uptime">
                <p class="el-input__inner">
                  <span v-if="!times">{{postData.S_OP_HI_WearTime}}</span>
                  <span v-if="times">不详</span>
                </p>
                <el-dropdown-menu slot="dropdown" class="xlk">
                  <el-dropdown-item style="text-align: center" command="a">不详</el-dropdown-item>
                  <el-dropdown-item>
                    <el-date-picker
                        v-show="istimezj"
                        class="timezj"
                        size="mini"
                        v-model="postData.S_OP_HI_WearTime"
                        align="top"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        @change="timebh"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-dropdown-item>
                </el-dropdown-menu>
             </el-dropdown>
           </div>
        </div>
        <div class="jjxx">
          <p>旧镜信息</p>
          <el-input class="input" v-model="postData.S_OP_HI_Frame_note" placeholder="请输入内容"></el-input>
          <el-button @click="gosbxx">导入设备信息</el-button>
        </div>
        <ul class="xxxx">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>ADD</li>
          <li>旧镜VA</li>
          <li>瞳距</li>
        </ul>
        <ul class="xxxx">
          <li>OD</li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_SPHOD" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_CYLOD" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_AxesOD" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_AddOD" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_VAOD" placeholder="请输入内容"></el-input></li>
          <li class="last"><el-input class="pj" v-model="postData.S_OP_HI_Frame_PDOD" placeholder="请输入内容"></el-input><span>mm</span></li>
        </ul>
        <ul class="xxxx">
          <li>OS</li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_SPHOS" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_CYLOS" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_AxesOD" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_AddOS" placeholder="请输入内容"></el-input></li>
          <li><el-input class="pj" v-model="postData.S_OP_HI_Frame_VAOS" placeholder="请输入内容"></el-input></li>
          <li class="last"><el-input class="pj" v-model="postData.S_OP_HI_Frame_PDOS" placeholder="请输入内容"></el-input><span>mm</span></li>
        </ul>
     </div>
   </div>
</template>

<script>
    export default {
        name: "wearing",
        data() {
          return {
            chooseid: '1',
            ids: 2,
            time: '',    //显示时间的变量
            isshowtime: false,   //是否开启旧镜验配时间选择
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
              shortcuts: [{
                text: '今天',
                onClick(picker) {
                  picker.$emit('pick', new Date());
                }
              }, {
                text: '昨天',
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24);
                  picker.$emit('pick', date);
                }
              }, {
                text: '一周前',
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', date);
                }
              }]
            },
            istimezj: true,   ///是否展示选择时间组件
            dialogVisible: false,
            times: false,  //是否显示时间或者是不详变量
            postData: {}
          }
        },
        props: {
          idx: {
            default: -1
          }
        },
        watch: {
          idx: function (n, o) {
            if (n == this.ids && this.chooseid != this.$store.state.physicianVisits.xzConsulting) {
              if (this.$store.state.physicianVisits.xzConsulting) {
                this._api.eyeTest.getVisionTest()
                    .then(res => {
                      //console.log(res.GetListResult)
                      if (res.GetListResult) {
                        this.$store.commit('eyeTest/upDatas', this._clJson(res.GetListResult[0].S_OP_Json))
                        this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
                      }
                      this.chooseid = this.$store.state.physicianVisits.xzConsulting
                      this.$store.commit('eyeTest/upChooseId', this.chooseid)
                    })
              }
            }
          },
          postData: {
            handler(n, o) {
              this.$store.commit('eyeTest/upDatas', n)
            },
            deep: true
          }
        },
        created() {
          this.postData = this.$store.state.eyeTest.datas
        },
        methods: {
            qrttime(){
               //console.log(111)
            },
            uptime(e) {
              if (e == 'a') {
                this.times = true
                this.postData.sj = '不详'
                this.postData.S_OP_HI_WearTime = ''
              }
            },
            timebh(e) {
              this.times = false
              this.postData.sj = ''
            },
            gosbxx() {
              this.$router.push({path: '/home/equipmentInformation'})
            }
          }
    }
</script>

<style scoped lang="scss">
   .wearing {width: 88vw}
   .content {
      width: 90%;
      margin: 0 auto;
      .pj {
        margin: 0.1rem;
      }
   }
   .time {
      display: flex;
      justify-content: start;
      align-items: center;
      position: relative;
      .time-xz {
         width: 120px;
         height: 40px;
         line-height: 25px;
         //border: 1px solid #000000;
         position: relative;
        margin-left: 50px;
        border-radius: 5px;
        .sx-op {
          width: 100%;
          height: 100%;
          .shijian {
            width: 120px;
            height: 25px;
          }
        }
        .xlk {text-align: center;}
      }
      .time-op {
         position: absolute;
         width: 140px;
         z-index: 9;
         background: #efeded;
         left: 50%;
         transform: translateX(-50%);
         border-radius: 5px;
         top: 28px;
         cursor: pointer;
         p {
            padding: 5px 0;
            margin: 0 5px;
         }
         p:nth-child(1){border-bottom: 1px solid #939393}
      }
      .timezj {
        width: 130px;
      }
   }
   .jjxx {
     display: flex;
     align-items: center;
     justify-content: start;
     padding: 20px 0;
     .drsb {
       padding-top: 6px;
       padding-bottom: 6px;
       border: 1px solid #000000;
     }
     .input {
       width: 4rem;
       margin: 0.2rem;
     }
   }
   .xxxx {
     width: 100%;
     display: flex;
     justify-content: start;
     align-items: center;
     padding-top: 20px;
     li {
       width: 11%;
       text-align: center;
       display: flex;
       align-items: center;
       justify-content: center;
       input {
         width: 80%;
       }
     }
     li:nth-child(1) {
       justify-content: start;
       width: 5%;
     }
     .last {
       width: 11%;
       input {
         width: 75%;
         margin-right: 2px;
       }
     }
   }
</style>
